import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Heading,
    Flex,
    Box,
    Input,
    Text,
    IconButton
  } from '@chakra-ui/react'
  import {MdAddCircle, MdAdd, MdRemoveCircle, MdRemove} from "react-icons/md"
  import AddInventory from "./AddInventory.js"

  function Inventory({items, coins, addItem, isEditable, updateCoinValue, increaseItemQuantity, decreaseItemQuantity}) {
    return (
        <Flex direction="column" gap="2em">
        <TableContainer>
            <Table variant="simple" size="sm">
                <Thead>
                    <Tr>
                        <Th color="pink.500">Name</Th>
                        <Th color="pink.500" textAlign="left">Quantity</Th>
                        <Th color="pink.500" textAlign="right">Weight</Th>
                        <Th color="pink.500" textAlign="right">Value</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                        {items.map((item) => {return (
                            <Tr key={item.name}>
                                <Td>{item.name}</Td>
                                <Td textAlign="center" isNumeric>
                                    <Flex gap="1em" align="center" justify="flex-start">
                                    <IconButton colorScheme="whiteAlpha" variant="outline" data-id={item.name} onClick={() => decreaseItemQuantity(item.name)} size="xxs" icon={<MdRemove/>}/>
                                    <Text>{item.quantity}</Text>
                                    <IconButton colorScheme="whiteAlpha" variant="outline" data-name={item.name} onClick={() => increaseItemQuantity(item.name)} size="xxs" icon={<MdAdd/>}/>

                                    </Flex>
                                </Td>
                                <Td isNumeric>{item.weight}</Td>
                                <Td textAlign="right">{item.value}</Td>
                            </Tr>
                        )} )}
                    </Tbody>
                
            </Table>
        </TableContainer>
        {isEditable ? <AddInventory addItem={addItem}/> : null}
        <Heading size="sm">Currency</Heading>
        <Flex gap="1em">
            {coins.map((coin) => <CoinBlock updateCoinValue={updateCoinValue} isEditable={isEditable} coin={coin} key={coin.name} />)}
        </Flex>
        </Flex>
    )
  }

  function CoinBlock({coin, isEditable, updateCoinValue}) {


    return (
        <Flex className="statBlock" p="0.5em" direction="row" align="center" justifyContent="space-between" bg="gray.700" borderRadius="lg" gap={1} minWidth="100px" maxWidth="200px">
            <Text fontSize="sm" bg="gray.600" p="0.25em" borderRadius="lg">{coin.name}</Text>
            {isEditable ? <Input defaultValue={coin.value} variant="unstyled" bg="gray.700" id={coin.name} onChange={updateCoinValue} textAlign="right" borderRadius="lg" fontSize="1em" p="0.25em"></Input> : <Box fontSize="1em" bg="gray.700" p="0.25em" borderRadius="lg" textAlign="right" w="90%">{coin.value}</Box>}

        </Flex>
    )
}

  export default Inventory