import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Input,
    Flex,
    Textarea,
    Select
  } from '@chakra-ui/react'
import {useState} from "react"

function AddInventory({addItem}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [item, setItem] = useState({
        name: null,
        quantity: null,
        value: null,
        weight: null,
    })

    function handleChange(event) {
        let value = event.target.id
        let oldItem = {...item}
        oldItem[value] = event.target.value
        setItem(oldItem)
    }
    return (
      <>
        <Button variant="outline" colorScheme="pink" size ="sm" onClick={onOpen}>Add items</Button>
  
        <Modal isOpen={isOpen} onClose={onClose} maxW="50%">
          <ModalOverlay />
          <ModalContent bg="gray.800">
            <ModalHeader color="gray.50">Add an item...</ModalHeader>
            <ModalCloseButton color="gray.50" />
            <ModalBody color="gray.50" gap="1em">
                <Flex direction="column" gap="1em">
                <Flex direction="column">
                <Flex gap="1em">
                <Input placeholder="Item name" variant="flushed" id="name" colorScheme="pink" color="gray.50" onChange={handleChange}></Input>
                <Input variant="flushed" type="number" placeholder="Quantity" id="quantity" onChange={handleChange}></Input>
                </Flex>
                <Flex gap="1em">
                    <Input placeholder="Value (num + GP/SP/CP)" variant="flushed" id="value" colorScheme="pink" onChange={handleChange}></Input>
                    <Input type="number" placeholder="Weight in lbs." variant="flushed" id="weight" colorScheme="pink" onChange={handleChange}></Input>
                </Flex>
                
                </Flex>

                </Flex>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='pink' mr={3} size="sm" onClick={() => {addItem(item); onClose()}}>
                Add
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default AddInventory