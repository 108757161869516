import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    IconButton,
    useDisclosure,
    Button,
    Input,
    Flex,
    Textarea,
    Select
  } from '@chakra-ui/react'
import {useState} from "react"
import {FeatureContainer} from "./Features.js"
import {MdAdd} from "react-icons/md"


function AddFeat({addFeat}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [feat, setFeat] = useState({
        name: null,
        description: null,
        level: null,
        type: null,
    })

    function handleChange(event) {
        let value = event.target.id
        let oldFeat = {...feat}
        oldFeat[value] = event.target.value
        setFeat(oldFeat)
    }
    return (
      <>
        <Button variant="outline" colorScheme="pink" onClick={onOpen}>Add features</Button>
  
        <Modal isOpen={isOpen} onClose={onClose} maxW="50%">
          <ModalOverlay />
          <ModalContent bg="gray.800">
            <ModalHeader color="gray.50">Add a feature...</ModalHeader>
            <ModalCloseButton color="gray.50" />
            <ModalBody color="gray.50" gap="1em">
                <Flex direction="column" gap="1em">
                <Flex direction="column">
                <Flex>
                <Input placeholder="Feature name" variant="flushed" id="name" colorScheme="pink" color="gray.50" onChange={handleChange}></Input>
                <Input type="number" w="20%" placeholder="Level" id="level" onChange={handleChange}></Input>
                </Flex>
                <Textarea id="description" placeholder="Feature description" variant="flushed" onChange={handleChange}></Textarea>
                <Select id="type" onChange={handleChange}>
                <option value='feats'>Feat</option>
                <option value='race'>Race feature</option>
                <option value='class'>Class feature</option>
                </Select>
                </Flex>

                <FeatureContainer feature={feat}></FeatureContainer>
                </Flex>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='pink' mr={3} size="sm" onClick={() => {addFeat(feat); onClose()}}>
                Add
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

function AddAttack({addAttack}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [attack, setAttack] = useState({
      name: null,
      modifier: null,
      damageDice: null,
  })

  function handleChange(event) {
      let value = event.target.id
      let oldAttack = {...attack}
      oldAttack[value] = event.target.value
      setAttack(oldAttack)
  }
  return (
    <>
      <Button variant="outline" colorScheme="pink" w="20%" onClick={onOpen}>Add attack</Button>

      <Modal isOpen={isOpen} onClose={onClose} maxW="50%">
        <ModalOverlay />
        <ModalContent bg="gray.800">
          <ModalHeader color="gray.50">Add an attack</ModalHeader>
          <ModalCloseButton color="gray.50" />
          <ModalBody color="gray.50" gap="1em">
              <Flex direction="column" gap="1em">
              <Flex direction="column">
              <Flex direction="column" gap="1em">
              <Input placeholder="Attack/weapon name" variant="flushed" id="name" colorScheme="pink" color="gray.50" onChange={handleChange}></Input>
              <Input placeholder="Modifier to hit" variant="flushed" id="modifier" colorScheme="pink" color="gray.50" onChange={handleChange}></Input>
              <Input placeholder="Damage dice and bonus, e.g 1d8+3" variant="flushed" id="damageDice" colorScheme="pink" color="gray.50" onChange={handleChange}></Input>
              </Flex>
              
              </Flex>

              </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='pink' mr={3} size="sm" onClick={() => {addAttack(attack); onClose()}}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function AddResource({addResource}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [resource, setResource] = useState({
      name: null,
      current: null,
      max: null,
      rechargesOn: null
  })

  function handleChange(event) {
      let value = event.target.id
      let oldResource = {...resource}
      oldResource[value] = event.target.value
      oldResource.current = oldResource.max
      setResource(oldResource)
  }
  return (
    <>
      <IconButton variant="outline" colorScheme="pink" onClick={onOpen} size="sm" icon={<MdAdd />}></IconButton>

      <Modal isOpen={isOpen} onClose={onClose} maxW="50%">
        <ModalOverlay />
        <ModalContent bg="gray.800">
          <ModalHeader color="gray.50">Add a resource...</ModalHeader>
          <ModalCloseButton color="gray.50" />
          <ModalBody color="gray.50" gap="1em">
              <Flex direction="column" gap="1em">
              <Flex direction="column">
              <Flex>
              <Input placeholder="Resource name" variant="flushed" id="name" colorScheme="pink" color="gray.50" onChange={handleChange}></Input>
              <Input type="number" w="20%" placeholder="Uses" id="max" onChange={handleChange}></Input>
              </Flex>
              <Select id="rechargesOn" onChange={handleChange}>
              <option value='longRest'>Long rest</option>
              <option value='shortRest'>Short rest</option>
              </Select>
              </Flex>

              </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='pink' mr={3} size="sm" onClick={() => {addResource(resource); onClose()}}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddFeat
export {AddAttack, AddResource}