import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Button, Flex, Heading, Text } from '@chakra-ui/react'
import AddSpells from "./Spellbook.js"
import {EditSpellSlots} from "./Spellbook.js"

function Spells({spells, useSpellSlot, addSpell, isEditable, deleteSpell, saveSpellSlots}) {
    return (
        <Flex direction="column" gap="2em">
            {spells.map((spellLevel) => <SpellSlotContainer deleteSpell={deleteSpell} isEditable={isEditable} key={spellLevel.level} spellLevel={spellLevel} useSpellSlot={useSpellSlot}/>)}
            {isEditable ? 
            <Flex gap="1em">
                <AddSpells addSpell={addSpell} />
                <EditSpellSlots saveSpellSlots={saveSpellSlots} spellSlots={spells}/>
            </Flex> : null}
        </Flex>
    )
}

function SpellSlotContainer({spellLevel, useSpellSlot, isEditable, deleteSpell}) {
    return (
        <Flex bg="gray.800" borderRadius="lg sm" p="0px" justify="space-between" gap="1em" flex="1 1 0" borderBottom="1px solid white">
        <Accordion allowToggle w="100%" borderWidth="1px" display="flex" flexDirection="column" gap="0.5em" borderStyle="none">
            <AccordionItem borderWidth="0px" bg="gray.800" borderRadius="lg" borderStyle="none">
                <Flex>
                <AccordionButton borderWidth="0px" display="flex" align="space-between" justify="space-between">
    
                        <Heading size="sm">{(spellLevel.level == "0") ? "Cantrips" : "Level " + spellLevel.level}</Heading>
       
                    
                </AccordionButton>
                <Heading size="sm" ml="auto" onClick={useSpellSlot} data-id={spellLevel.level} whiteSpace="nowrap">{(spellLevel.totalSpellSlots) ? spellLevel.spellSlotsRemaining + " / " + spellLevel.totalSpellSlots : ""}</Heading>
                </Flex>
            <AccordionPanel pb={4} borderBottomWidth="0px" gap="1em" display="flex" flexDirection="column">
                {spellLevel.spells.map((spell) => <SpellContainer deleteSpell={deleteSpell} spellLevel={spellLevel.level} isEditable={isEditable} spell={spell} key={spell.name}/>)}
            </AccordionPanel>
            </AccordionItem>
            
        </Accordion>
        </Flex>
    )
}

function SpellContainer({spell, isEditable, spellLevel, deleteSpell}) {
    return (
    <Flex bg="gray.900" borderRadius="lg" p="0px" justify="space-between" gap="1em" flex="1 1 0">
        <Accordion allowToggle w="100%" borderWidth="0px" display="flex" flexDirection="column" gap="0.5em" borderStyle="none">
            <AccordionItem borderWidth="0px" bg="gray.700" borderRadius="lg" borderStyle="none">
                <AccordionButton borderWidth="0px" display="flex" align="space-between" justify="space-between">
    
                        <Heading size="sm">{spell.name}</Heading>
                        <Heading size="sm" ml="auto">{spell.castingTime}</Heading>
       
                    
                </AccordionButton>
            <AccordionPanel pb={4} borderBottomWidth="0px">
                {spell.description}
                <br /> <br />
                <Flex gap="0.5em" align="space-between" direction="column">
                <Text>Components: {spell.components}  </Text>
                <Text>Range: {spell.range}  </Text>
                <Text>Target: {spell.target}  </Text>
                <Text>School: {spell.school}  </Text>
                <Text>Duration: {spell.duration} </Text>
                {isEditable ? <Button variant="outline" colorScheme="pink" onClick={deleteSpell} data-level={spellLevel} data-id={spell.name}>Delete</Button> : null}
                </Flex>
            </AccordionPanel>
            </AccordionItem>
            
        </Accordion>
        </Flex>

    )
}

export default Spells
export { SpellContainer }
