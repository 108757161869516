import { Flex, Spacer, Box, Tag, TagLabel, Heading, Input, Accordion, AccordionItem, Button, AccordionButton, AccordionPanel, AccordionIcon, Text} from '@chakra-ui/react'
import AddFeat from "./AddFeat.js"

function Features({features, addFeat, isEditable, deleteFeature}) {
    return (
        <Flex direction="column" gap="2em">
            <Flex direction="column" gap="0.5em">
                <Heading size="sm">Race</Heading>
                {features.race.map((feature) => <FeatureContainer deleteFeature={deleteFeature} featureType="race" key={feature.name} feature={feature} isEditable={isEditable}/>)}
            </Flex>
            <Flex direction="column" gap="0.5em">
                <Heading size="sm">Class</Heading>
                {features.class.sort((o1, o2) => o1.level - o2.level).map((feature) => <FeatureContainer deleteFeature={deleteFeature} featureType="class" key={feature.name} feature={feature} isEditable={isEditable}/>)}

            </Flex>
            <Flex direction="column" gap="0.5em">
                <Heading size="sm">Feats</Heading>
                {features.feats.sort((o1, o2) => o1.level - o2.level).map((feature) => <FeatureContainer deleteFeature={deleteFeature} featureType="feats" key={feature.name} feature={feature} isEditable={isEditable}/>)}

            </Flex>
            {isEditable ? <AddFeat addFeat={addFeat} /> : null}
        </Flex>
    )
}

function FeatureContainer({feature, isEditable, featureType, deleteFeature}) {
    return (
    <Flex bg="gray.800" borderRadius="lg" p="0px" justify="space-between" gap="1em" flex="1 1 0">
        <Accordion allowToggle w="100%" borderWidth="0px" display="flex" flexDirection="column" gap="0.5em" borderStyle="none">
            <AccordionItem borderWidth="0px" bg="gray.700" borderRadius="lg" borderStyle="none">
                <AccordionButton borderWidth="0px" display="flex" align="space-between" justify="space-between">
    
                        <Heading size="sm">{feature.name}</Heading>
                        <Heading size="sm" ml="auto">{feature.level}</Heading>
       
                    
                </AccordionButton>
            <AccordionPanel pb={4} borderBottomWidth="0px">
                <Flex direction="column" gap="1em">
                    {feature.description}
                    {isEditable ? <Button size="xs" colorScheme="pink" onClick={deleteFeature} variant="outline" w="10%" data-type={featureType} data-id={feature.name}>Delete</Button> : null}
                </Flex>
            </AccordionPanel>
            </AccordionItem>
            
        </Accordion>
        </Flex>

    )
}

export default Features
export {FeatureContainer}