import {Flex, Progress, Box, Button, Text, ButtonGroup, Tag, TagLabel, Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor, useDisclosure, NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper, 
    Switch,
    IconButton, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
    import {GiCampfire} from "react-icons/gi"
    import {useState} from "react"


function Rest({longRest, shortRest, hitDiceMax}) {
    const { isOpen, onToggle, onClose } = useDisclosure()
    const [hitDice, setHitDice] = useState(0)

    return (
        <Popover
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={onClose}
          placement='bottom'
          closeOnBlur={true}
          colorScheme='pink'
        >
          <PopoverTrigger>
          <IconButton icon={<GiCampfire/>} colorScheme="pink" onClick={onToggle}></IconButton>
          </PopoverTrigger>
          <PopoverContent bg="gray.700" opacity="1" w="400px">
            {/* <PopoverHeader fontWeight='semibold'>Take a rest...</PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton /> */}
            <PopoverBody bg="gray.700" display="flex" align="flex-start" justify="center" direction="column">
                <Tabs colorScheme="pink" isFitted w="100%">
                    <TabList>
                        <Tab>Short rest</Tab>
                        <Tab>Long rest</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Flex direction="column" gap="1em">
                            <Text>Spend hit dice, recover resources</Text>
                            <Flex>
                            <Text>How many hit die to spend?</Text>
                            <NumberInput min={0} max={hitDiceMax} id="hpChange">
                            <NumberInputField onChange={(e) => setHitDice(e.target.value)}/>
                            <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                            </NumberInputStepper>
                            </NumberInput>
                            </Flex>
                            <Button variant="outline" colorScheme="pink" onClick={() => shortRest(hitDice)}>Take a short rest</Button>
                            </Flex>
                        </TabPanel>
                        <TabPanel>
                            <Flex direction="column" gap="1em">
                            <Text>Regain all HP, resources and spell slots.</Text>
                            <Button variant="outline" colorScheme="pink" onClick={longRest}>Rest</Button>
                            </Flex>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </PopoverBody>
          </PopoverContent>
        </Popover>
    )
}

export default Rest