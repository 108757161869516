import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Character from "./Character.js"
import Actions from "./Actions.js"
import Spells from "./Spells.js"
import Inventory from "./Inventory.js"
import Features from "./Features.js"
import Login from "./Login.js"

function MainTab({character, addAttack, changeSpellcastingStat, removeAttack, saveSpellSlots, decreaseItemQuantity, increaseItemQuantity, selectCharacter, updateCoinValue, deleteSpell, useSpellSlot, addSpell, addFeat, addItem, isEditable, handleCharacterChange, handleTraitChange, deleteFeature}) {

    return (
        <Tabs colorScheme="pink" isFitted overflowY="auto" p="1em">
  <TabList>
    <Tab>Character</Tab>
    <Tab>Actions</Tab>
    <Tab>Spells</Tab>
    <Tab>Inventory</Tab>
    <Tab>Features</Tab>
    {/* <Tab>Test</Tab> */}
  </TabList>

  <TabPanels overflowY="auto">
    <TabPanel>
        <Character handleCharacterChange={handleCharacterChange} handleTraitChange={handleTraitChange} isEditable={isEditable} character={character}></Character>
    </TabPanel>
    <TabPanel overflowY="auto">
      <Actions attacks={character.attacks} changeSpellcastingStat={changeSpellcastingStat} spellcastingStat={character.spellcastingStat} removeAttack={removeAttack} addAttack={addAttack} stats={character.stats} pb={character.senses.pb} isEditable={isEditable}></Actions>
    </TabPanel>
    <TabPanel>
      <Spells isEditable={isEditable} saveSpellSlots={saveSpellSlots} deleteSpell={deleteSpell} spells={character.spells} addSpell={addSpell} useSpellSlot={useSpellSlot}/>
    </TabPanel>
    <TabPanel>
      <Inventory isEditable={isEditable} decreaseItemQuantity={decreaseItemQuantity} increaseItemQuantity={increaseItemQuantity} updateCoinValue={updateCoinValue} addItem={addItem} coins={character.coins} items={character.items}/>
    </TabPanel>
    <TabPanel p="1em">
      <Features addFeat={addFeat} deleteFeature={deleteFeature} isEditable={isEditable} features={character.features}/>
    </TabPanel>
    <TabPanel>
    </TabPanel>
  </TabPanels>
</Tabs>
    )
}

export default MainTab