import {Flex, Progress, Box, Button, Text, ButtonGroup, Tag, TagLabel, Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor, useDisclosure, NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper, 
    Switch,
    IconButton} from '@chakra-ui/react'
import {StaticBlock} from "./Stats.js"
import Login from "./Login.js"
import {GiCampfire} from "react-icons/gi"
import { MdEditDocument, MdDoneOutline, MdExitToApp } from "react-icons/md";
import {AddResource} from "./AddFeat.js"
import {useState} from "react"
import Rest from "./Rest.js"
import { FailuresDeathSaving, SuccessDeathSaving } from './DeathSavingThrows.js';

function PopoverStaticBlock({stat, handleChange, saveHP, healDamage, takeDamage, addTempHP}) {
    const { isOpen, onToggle, onClose } = useDisclosure()
    let adjustmentValue = 0
  
    return (
      <>
        <Popover
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={onClose}
          placement='bottom'
          closeOnBlur={true}
          colorScheme='pink'
        >
          <PopoverTrigger>
          <Flex className="statBlock" p="0.5em" flex="1 1 0px" direction="column" justify="space-between" align="center" bg="gray.600" borderRadius="lg" onClick={onToggle}>
            <Box fontSize={["1em", "1em", "1em", "1.25em"]} color={stat.temp > 0 ? "teal.300" : "green.300"}>{stat.temp > 0 ? stat.value + stat.temp : stat.value}</Box>
            <Text fontSize={["xs", "xs", "xs", "sm"]}>{stat.name}</Text>
        </Flex>
          </PopoverTrigger>
          <PopoverContent bg="gray.700" opacity="1" w="400px">
            <PopoverHeader fontWeight='semibold'>Adjust HP</PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody bg="gray.700" display="flex" align="center" justify="center" direction="column">
            <Flex direction="column" gap="2em" p="1em">
            <NumberInput min={0} id="hpChange">
                <NumberInputField onChange={(e) => adjustmentValue = e.target.value}/>
                <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
            <Flex gap="1em">
              <Button colorScheme="green" variant="outline" size="sm" onClick={() => healDamage(Number(adjustmentValue))}>Heal</Button>
              <Button colorScheme="red" variant="outline" size="sm" onClick={() => takeDamage(Number(adjustmentValue))}>Take damage</Button>
              <Button colorScheme="teal" variant="outline" size="sm" onClick={() => addTempHP(Number(adjustmentValue))}>Temporary HP</Button>
            </Flex>
            </Flex>
            {/* <Button colorScheme='pink' ml="auto" mt="auto" mb="auto" onClick={saveHP} size="sm">Apply</Button> */}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </>
    )
  }

function HP({hp, ac, classResource, adjustMaxHP, shortRest, addTempHP, deleteResource, characterSelectScreen, healDamage, takeDamage, addResource, handleACChange, hasInspiration, hitDice, longRest, handleChange, saveHP, reduceHitDie, decreaseResource, saveChar, getChar, toggleEdit, saveEdit, isEditable}) {
    return (
        <Flex direction="column" gap="1em" p="1em" justify="space-around" align="space-around">
            <Flex gap="1em">
                
                {!isEditable ? <PopoverStaticBlock stat={{name: "HP" , value: hp.current, max: hp.max, temp:hp.temp}} addTempHP={addTempHP} takeDamage={takeDamage} healDamage={healDamage} handleChange={handleChange} saveHP={saveHP}/>
                : <StaticBlock isEditable={isEditable}  id="hp" stat={{name: "Max HP", value:hp.max}} handleChange={adjustMaxHP}/>}
                {hp.current > 0 ? 
                <>
                <StaticBlock isEditable={isEditable} handleChange={handleACChange} id="ac" stat={{name: "AC", value: ac}}/>
                <StaticBlock stat={{name: `HD`, value: hitDice.current}} handleClick={reduceHitDie}/> 
                </>
              : <Flex direction="column" justify="space-evenly" gap="1em">
                <SuccessDeathSaving/>
                <FailuresDeathSaving/>
                </Flex>}
            </Flex>
    
            <Flex gap="1em" align="center" overflowX="auto" overflowY="clip" maxHeight="50px" sx={{ "::-webkit-scrollbar":{
           display:"none",
       },
         "scrollbar-width":"none"}}>
                {isEditable ? <AddResource addResource={addResource}/> : null}
                {classResource.length == 0 ? <Text>Add a resource</Text> : null}
                {classResource.map((classResource) => {return (
                      <Flex gap={2} onClick={(e) => {if (isEditable === false) {decreaseResource(e)} else {deleteResource(e)}}} maxHeight="50px" maxW="200px" justify="space-between" bg="gray.600" p="0.5em" borderRadius="lg" as="button" flex="1 1 0" key={classResource.name} data-id={classResource.name} whiteSpace="nowrap" fontSize="sm">
                      {classResource.name}
                      <Tag size="sm" variant="subtle" colorScheme="pink" data-id="classResource.name" onClick={(e) => {if (isEditable === true) {deleteResource(e)}}}> 
                          <TagLabel>{isEditable ? "X" : classResource.current}</TagLabel>
                      </Tag>
                      </Flex>
                )})}
              
                {/* <Flex gap={3} bg="gray.600" p="0.5em" borderRadius="lg" fontSize="sm">
                <Text size="sm">Inspiration</Text>
                <Tag size="sm" variant="subtle" colorScheme={hasInspiration ? "pink" : "whiteAlpha"}>
                    <TagLabel>{hasInspiration ? "X" : null}</TagLabel>
                </Tag>
                </Flex> */}
                </Flex>
            <Flex gap="1em">   
                <Rest longRest={longRest} shortRest={shortRest} hitDiceMax={hitDice.max}/>
                {isEditable ? 
                <IconButton icon={<MdDoneOutline/>} colorScheme="green" size="md" onClick={saveEdit}>Save</IconButton>
                : <IconButton icon={<MdEditDocument/>} colorScheme="purple" size="md" onClick={toggleEdit}>Edit</IconButton> 
                }
                <IconButton icon={<MdExitToApp/>} colorScheme="whiteAlpha" size="md" onClick={() => {saveChar(); characterSelectScreen()}}/>
            </Flex>

        </Flex>
    )
}

export default HP