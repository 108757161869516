import { Flex, Spacer, Box, Tag, Grid, GridItem, TagLabel, Select, TagLeftIcon, TagRightIcon, IconButton, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { GiDiceFire, GiDiceTwentyFacesTwenty } from "react-icons/gi"
import {MdClose} from "react-icons/md"
import {AddAttack} from "./AddFeat.js"

function AttackBox({action, isEditable, removeAttack}) {
    return (
        <Flex bg="gray.600" borderRadius="lg" p="1em" justify="space-between" gap="1em" flex="1 1 0">
            <Tag variant="subtle" colorScheme="pink" size="md">
                {isEditable ? <TagLeftIcon as={MdClose} onClick={() => removeAttack(action.name)}></TagLeftIcon> : null}
                <TagLabel>{action.name}</TagLabel>
            </Tag>
            <Tag size="md" colorScheme="purple">
                <TagLeftIcon as={GiDiceTwentyFacesTwenty}></TagLeftIcon>
                <TagLabel>{action.modifier}</TagLabel>
            </Tag>
            <Tag size="md" colorScheme="messenger">
                <TagLeftIcon as={GiDiceFire}></TagLeftIcon>
                <TagLabel>{action.damageDice}</TagLabel>
            </Tag>
        
        
        
        </Flex>
    )
}

function ActionBox({actions}) {
    return (
        <Flex bg="gray.700" borderRadius="lg" p="1em" justify="space-between" gap="1em" flex="1 1 0">
        <Accordion allowToggle w="100%" borderWidth="0px" display="flex" flexDirection="column" gap="0.5em" borderStyle="none">
            {actions.map((action) => 
            <AccordionItem borderWidth="0px" bg="gray.600" borderRadius="lg" borderStyle="none" key={action.name}>
                <AccordionButton borderWidth="0px">
                    <Flex align="space-between" borderWidth="0px" w="100%">
                        <Heading size="sm">{action.name}</Heading>
       
                    </Flex>
                </AccordionButton>
            <AccordionPanel pb={4} borderBottomWidth="0px">
                {action.description}
            </AccordionPanel>
            </AccordionItem>
            )}
            
        </Accordion>
        </Flex>
    )
}

function Actions({actions, attacks, stats, pb, addAttack, isEditable, removeAttack, changeSpellcastingStat, spellcastingStat}) {
    const testAttack = {"name": "Dagger +1",
                        "modifier": "+9",
                        "damageDice": "2d4 + 6"}

    const spellMod = ((stats[spellcastingStat] - 10) / 2) + pb + 8
    const spellAttackMod = ((stats[spellcastingStat] - 10) / 2) + pb

    const genericActions = [{"name": "Dash",
                            "description": "When you take the Dash action, you gain extra movement for the current turn. The increase equals your speed, after applying any modifiers. With a speed of 30 feet, for example, you can move up to 60 feet on your turn if you dash."},
                        {"name": "Disengage",
                        "description": "If you take the Disengage action, your movement doesn’t provoke opportunity attacks for the rest of the turn."},
                        {"name": "Dodge",
                        "description": "When you take the Dodge action, you focus entirely on avoiding attacks. Until the start of your next turn, any attack roll made against you has disadvantage if you can see the attacker, and you make Dexterity saving throws with advantage. You lose this benefit if you are incapacitated (as explained in Conditions ) or if your speed drops to 0."},
                        {"name": "Help",
                         "description": "You can lend your aid to another creature in the completion of a task. When you take the Help action, the creature you aid gains advantage on the next ability check it makes to perform the task you are helping with, provided that it makes the check before the start of your next turn. Alternatively, you can aid a friendly creature in attacking a creature within 5 feet of you. You feint, distract the target, or in some other way team up to make your ally’s attack more effective. If your ally attacks the target before your next turn, the first attack roll is made with advantage."},
                        {"name": "Hide",
                         "description": "When you take the Hide action, you make a Dexterity (Stealth) check in an attempt to hide, following the rules for hiding. If you succeed, you gain certain benefits, as described in the Unseen Attackers and Targets section."},
                        {"name": "Search",
                        "description": "When you take the Search action, you devote your attention to finding something. Depending on the nature of your search, the GM might have you make a Wisdom (Perception) check or an Intelligence (Investigation) check." },
                        {"name": "Use an object",
                         "description": "You normally interact with an object while doing something else, such as when you draw a sword as part of an attack. When an object requires your action for its use, you take the Use an Object action. This action is also useful when you want to interact with more than one object on your turn."} 

                        
                        ]                    

    return (
        <Flex direction="column" gap="1em">
            <Flex direction="column" gap="1em">
                <Heading size="sm">Attacks</Heading>
                <Grid templateColumns="1fr 1fr" gap="1em">
                    {attacks.map((attack) => <GridItem key={attack.name}><AttackBox isEditable={isEditable} removeAttack={removeAttack} action={attack} key={attack.name}/></GridItem>)}
                    <GridItem><AttackBox action={{name: "Spellcasting", modifier: `+${spellAttackMod}`, damageDice:`DC: ${spellMod}`}}/></GridItem>
                </Grid>
                {isEditable ? 
                    <Flex align="center" gap="1em">
                    <AddAttack addAttack={addAttack}/>
                    <Flex direction="row" justify="center" align="center" whiteSpace="nowrap" gap="1em">
                        <Text mb="0px" p="0px">Set spellcasting stat:</Text>
                        <Select variant="flushed" onChange={(e) => changeSpellcastingStat(e)}>
                            <option value="charisma">Charisma</option>
                            <option value="wisdom">Wisdom</option>
                            <option value="intelligence">Intelligence</option>
                        </Select>
                    </Flex>
                </Flex> : null}
            </Flex>

            <Flex direction="column" gap="1em">
                <Heading size="sm">Generic</Heading>
                <Flex w="100%" gap="1em">
                    <ActionBox actions={genericActions}></ActionBox>
                   

                </Flex>
            </Flex>
        </Flex>
    )
}

export default Actions 