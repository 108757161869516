import {
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    Box,
  } from '@chakra-ui/react'

  const successSteps = [
    { title: 'First Success', description: 'Contact Info' },
    { title: 'Second Success', description: 'Date & Time' },
    { title: 'Third Success', description: 'Select Rooms' },
  ]

  const failureSteps = [
    { title: 'First Fail', description: 'Contact Info' },
    { title: 'Second Fail', description: 'Date & Time' },
    { title: 'Third Fail', description: 'Select Rooms' },
  ]
  
  function SuccessDeathSaving() {
    const { activeStep, setActiveStep } = useSteps({
      index: 1,
      count: successSteps.length,
    })

    
  
    return (
      <Stepper size='sm' index={activeStep} colorScheme="teal">
        {successSteps.map((step, index) => (
          <Step key={index} onClick={() => setActiveStep(index)}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
  
            <Box flexShrink='0'>
              {/* <StepTitle>{step.title}</StepTitle> */}
              {/* <StepDescription>{step.description}</StepDescription> */}
            </Box>
  
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    )
  }

  function FailuresDeathSaving() {
    const { activeStep, setActiveStep } = useSteps({
      index: 0,
      count: failureSteps.length,
    })


  
    return (
      <Stepper size='sm' index={activeStep} colorScheme="red">
        {failureSteps.map((step, index) => (
          <Step key={index} onClick={() => setActiveStep(index)}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
  
            <Box flexShrink='0'>
              {/* <StepTitle>{step.title}</StepTitle> */}
              {/* <StepDescription>{step.description}</StepDescription> */}
            </Box>
  
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    )
  }

  export {FailuresDeathSaving, SuccessDeathSaving}