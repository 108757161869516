import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Input,
    Text,
    Heading,
    Flex,
    Textarea
  } from '@chakra-ui/react'
import {useState} from "react"
import {SpellContainer} from "./Spells.js"

function AddSpells({addSpell}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [spell, setSpell] = useState({
        name: null,
        description: null,
        level: null,
        castingTime: null,
        components: null,
        range: null, 
        target: null,
        school: null,
        duration: null, 
    })

    function handleChange(event) {
        let value = event.target.id
        let oldSpell = {...spell}
        oldSpell[value] = event.target.value
        setSpell(oldSpell)
    }
    return (
      <>
        <Button variant="outline" colorScheme="pink" onClick={onOpen}>Add spells</Button>
  
        <Modal isOpen={isOpen} onClose={onClose} maxW="50%">
          <ModalOverlay />
          <ModalContent bg="gray.800">
            <ModalHeader color="gray.50">Add a spell...</ModalHeader>
            <ModalCloseButton color="gray.50" />
            <ModalBody color="gray.50" gap="1em">
                <Flex direction="column" gap="1em">
                <Flex direction="column">
                <Flex>
                <Input placeholder="Spell name" variant="flushed" id="name" colorScheme="pink" color="gray.50" onChange={handleChange}></Input>
                <Input type="number" w="20%" placeholder="Level" id="level" onChange={handleChange}></Input>
                </Flex>
                <Textarea id="description" placeholder="Spell description" variant="flushed" onChange={handleChange}></Textarea>
                <Input id="castingTime" placeholder="Action/BA?" variant="flushed" onChange={handleChange}></Input>
                <Input id="components" placeholder="Components: V S M?" variant="flushed" onChange={handleChange}></Input>
                <Input id="range" placeholder="Range in ft." variant="flushed" onChange={handleChange}></Input>
                <Input id="target" placeholder="Target" variant="flushed" onChange={handleChange}></Input>
                <Input id="school" placeholder="School of magic" variant="flushed" onChange={handleChange}></Input>
                <Input id="duration" placeholder="Duration" variant="flushed" onChange={handleChange}></Input>
                </Flex>

                <SpellContainer spell={spell}></SpellContainer>
                </Flex>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='pink' mr={3} size="sm" onClick={() => {addSpell(spell); onClose()}}>
                Add
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

function EditSpellSlots({spellSlots, saveSpellSlots}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [spellLevels, setSpellLevels] = useState(spellSlots)
  let latestSpellLevel = spellLevels.length
  let newSpellLevel = {
    level: spellLevels.length,
    totalSpellSlots: 0,
    spellSlotsRemaining: 0,
    spells: [] 

  }

  function handleChange(e) {
    let level = Number(e.target.getAttribute("data-id"))
    let value = Number(e.target.value)
    let spellLevelsCopy = [...spellLevels]
    spellLevelsCopy[level].totalSpellSlots = value
    setSpellLevels(spellLevelsCopy)


  }


  return (
<>
        <Button variant="outline" colorScheme="pink" onClick={onOpen}>Edit spell slots</Button>
  
        <Modal isOpen={isOpen} onClose={onClose} maxW="50%">
          <ModalOverlay />
          <ModalContent bg="gray.800">
            <ModalHeader color="gray.50">Edit spell slots</ModalHeader>
            <ModalCloseButton color="gray.50" />
            <ModalBody color="gray.50" gap="1em">
              <Flex direction="column" gap="1em">
              {spellLevels.filter((spellSlot) => spellSlot.level > 0).map((spellSlot) => 
                <Flex direction="column" key={spellSlot.level}>
                <Heading size="sm">Level {spellSlot.level}</Heading>
                <Input data-id={spellSlot.level} onChange={handleChange} placeholder={spellSlot.totalSpellSlots} variant="flushed"></Input>
                </Flex>
              )}
              <Button colorScheme="pink" onClick={() => setSpellLevels([...spellLevels, newSpellLevel])}>Add spell slot</Button>
              </Flex>
                
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='pink' mr={3} size="md" onClick={() => {saveSpellSlots(spellLevels); onClose()}}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
  )
}

export default AddSpells
export {EditSpellSlots}