import {
    Input,
    Button,
    Flex,
    Heading,
    Text
  } from '@chakra-ui/react'

import newCharacter from "./NewCharacter.js"
import {useState, useEffect} from "react"
import { auth, logInWithEmailAndPassword, registerWithEmailAndPassword, sendPasswordReset, query, logout, collection, db, where, getDocs} from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { addDoc, setDoc, doc, getDoc } from "firebase/firestore";
import NewCharacter from "./NewCharacter.js"
import movePacov from "./DatabaseActions.js"

function CharacterSelect({userID, selectCharacter}) {
    // set up create a character as a subcollection of current user profile
    const [allCharacters, setallCharacters] = useState([])
    const [toggleNewChar, settoggleNewChar] = useState(false)

    async function getAllChars() {
        let listOfCharacters = []

        try {
            console.log("Getting all chars from", userID)
            const querySnapshot = await getDocs(collection(db, "users", userID, "characters"));
            querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let characterObject = {}
            characterObject[doc.id] = doc.data()
            console.log(characterObject)
            listOfCharacters.push(characterObject)
            });
        } catch(e) {
            console.error("Error fetching characters", e)
        }
        setallCharacters(listOfCharacters)
    }

    useEffect(() => {
    getAllChars();

    }, [])

    return (
    <Flex bg="gray.800" width="100%" height="100vh" align="center" justify="center" gap="2em">
    <Flex direction="column" gap="1em" width="500px">
        <Heading size="md" color="pink.50">Enter a world of adventure</Heading>
        {allCharacters.map((characterObject) => <CharacterCard userID={userID} characterObject={Object.values(characterObject)[0]} selectCharacter={selectCharacter} dataID={Object.keys(characterObject)[0]} key={Object.keys(characterObject)[0]}/>)}
        <Flex gap="1em">
            <Button colorScheme="pink" onClick={() => settoggleNewChar(!toggleNewChar)}>Create new character</Button>
            <Button width="15%" colorScheme="pink" variant="outline" onClick={() => logout()}>Log out</Button>
        </Flex>
        { toggleNewChar ? 
        <CreateCharacter allCharacters={allCharacters} setallCharacters={setallCharacters} userID={userID}></CreateCharacter>
        : null
        }
    </Flex>
    </Flex>
    )
}

function CharacterCard({characterObject, selectCharacter, dataID, userID}) {

    return (
 
        <Flex data-id={dataID} bg="gray.700" textColor="gray.50" borderRadius="lg" as="button" p="1em" gap="1em" align="center" justify="space-between" onClick={(e) => selectCharacter(e, {userID})}>
            <Text bg="pink.800" borderRadius="lg" p="0.5em">{characterObject.name}</Text>
            <Text bg="pink.800" borderRadius="lg" p="0.5em">Level {characterObject.level}</Text>
            <Text bg="pink.800" borderRadius="lg" p="0.5em">Class: {characterObject.class}</Text>
        </Flex>
    )
}

function CreateCharacter({userID, allCharacters, setallCharacters}) {

    let characterName = ""

        async function createChar() {  
       
            try {
                console.log("Initializing")
                NewCharacter.name = characterName
                console.log(NewCharacter.name)
                await setDoc(doc(db, "users", userID, "characters", NewCharacter.name), NewCharacter);
                let characterObject = {}
                characterObject[NewCharacter.name] = NewCharacter
                setallCharacters([...allCharacters, characterObject])
                console.log("Document written with ID: ");
              } catch (e) {
                console.error("Error adding document: ", e);
              }
        }

    return (
        <Flex direction="column" gap="1em" bg="gray.700" p="1em" borderRadius="lg">
        <Heading size="md" color="pink.50">Create a new character</Heading>
        <Input variant="flushed" placeholder="Character name" color="pink.50" onChange={(e) => characterName = e.target.value }></Input>    
        <Button colorScheme="pink" w="15%" onClick={() => createChar()}>Create</Button>
        </Flex>
    )

}

export default CharacterSelect
export {CreateCharacter}