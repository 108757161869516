import { Flex, 
    Box, 
    Text, 
    Grid,
    GridItem, 
    Tag, 
    TagLabel, 
    Heading, 
    List,
    ListItem,
    ListIcon,
    OrderedList,
    Input,
    Textarea,
    UnorderedList, } from '@chakra-ui/react'

function TraitList({traits}) {
    return (
        <UnorderedList>
            {traits.map((trait) => <ListItem key={trait}>{trait}</ListItem>)}
        </UnorderedList>
    )
}    

function Character({character, isEditable, handleCharacterChange, handleTraitChange}) {

    return (
        <Flex direction="column" gap="1em" justify="center">
        <Grid templateRows="1fr 1fr" templateColumns="1fr 1fr 1fr" bg="gray.700" gap="1em" p="1em" borderRadius="lg" overflowY="auto">
            <GridItem>{isEditable ? <Input id="name" placeholder="Character name" variant="unstyled" onChange={handleCharacterChange}/> : character.name}</GridItem>
            <GridItem>{isEditable ? <Flex><Input id="class" placeholder="Class" variant="unstyled" onChange={handleCharacterChange}/><Input id="level" placeholder="Level" variant="unstyled"></Input></Flex> : character.class + " " + character.level}</GridItem>
            <GridItem>{isEditable ? <Input id="background" placeholder="Background" variant="unstyled" onChange={handleCharacterChange}/> : "Background: " + character.background}</GridItem>
            <GridItem>{isEditable ? <Input id="alignment" placeholder="Alignment" variant="unstyled" onChange={handleCharacterChange}/> : "Alignment: " + character.alignment}</GridItem>
            <GridItem>{isEditable ? <Input id="race" placeholder="Race/Ancestry" variant="unstyled" onChange={handleCharacterChange}/> : "Race: " + character.race}</GridItem>
        </Grid>
        <Grid templateRows="1fr 1fr" templateColumns="1fr 1fr 1fr" bg="gray.700" gap="1em" p="1em" borderRadius="lg" overflowY="auto">
            <GridItem>{isEditable ? <Input id="height" placeholder="Height" variant="unstyled" onChange={handleCharacterChange}/> : character.height}</GridItem>
            <GridItem>{isEditable ? <Input id="weight" placeholder="Weight" variant="unstyled" onChange={handleCharacterChange}/> : character.weight}</GridItem>
            <GridItem>{isEditable ? <Input id="hair" placeholder="Hair" variant="unstyled" onChange={handleCharacterChange}/> : "Hair: " + character.hair}</GridItem>
            <GridItem>{isEditable ? <Input id="age" placeholder="Age" variant="unstyled" onChange={handleCharacterChange}/> : "Age: " + character.age}</GridItem>
            <GridItem>{isEditable ? <Input id="skin" placeholder="Skin" variant="unstyled" onChange={handleCharacterChange}/> : "Skin: " + character.skin}</GridItem>
        </Grid>
        <Flex p="1em" alignItems="space-between" justify="space-between" bg="gray.700" borderRadius="lg">
            <Flex direction="column" gap="1em">
                <Heading size="md">Traits</Heading>
                {isEditable ? <><Text>{character.traits.join(", ")}</Text><Textarea id="traits" onChange={handleTraitChange} variant="unstyled" placeholder="Add up to three comma-separated traits"/></> :
                <TraitList traits={character.traits}></TraitList>
                }            </Flex>
            <Flex direction="column" gap="1em">
                <Heading size="md">Flaws</Heading>
                {isEditable ? <><Text>{character.flaws.join(", ")}</Text><Textarea id="flaws" onChange={handleTraitChange} variant="unstyled" placeholder="Add up to three comma-separated flaws"/></> :
                <TraitList traits={character.flaws}></TraitList>
                }
            </Flex>
            <Flex direction="column" gap="1em">
                <Heading size="md">Bonds</Heading>
                {isEditable ? <><Text>{character.bonds.join(", ")}</Text><Textarea id="bonds" onChange={handleTraitChange} variant="unstyled" placeholder="Add up to three comma-separated bonds"/></> :
                <TraitList traits={character.bonds}></TraitList>
                }
            </Flex>
            <Flex direction="column" gap="1em">
                <Heading size="md">Ideals</Heading>
                {isEditable ? <><Text>{character.ideals.join(", ")}</Text><Textarea id="ideals" onChange={handleTraitChange} variant="unstyled" placeholder="Add up to three comma-separated ideals"/></> :
                <TraitList traits={character.ideals}></TraitList>
                }
            </Flex>
        </Flex>
        </Flex>
    )
}

export default Character