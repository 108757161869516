import { Flex, Box, Text, Grid, GridItem, Tag, TagLabel, Heading, Switch, FormLabel } from '@chakra-ui/react'

function SavingSkills({stats, skills, proficiencyBonus, saves, isEditable, changeSkillProf, changeSaveProf}) {
    return (
        <Flex direction="column" align="space-around" justify="space-around" gap={4}>
            <Heading size="md">Saving throws</Heading>
            <Grid templateColumns="1fr 1fr" gap={2} p="0px" fontSize="sm">
                <SkillBox handleChange={changeSaveProf} isEditable={isEditable} statID="strength" isProficient={saves.strength} name="Strength" stat={stats.strength} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSaveProf} isEditable={isEditable} isProficient={saves.constitution} statID="constitution" name="Con" stat={stats.constitution} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSaveProf} isEditable={isEditable} isProficient={saves.dexterity} statID="dexterity" name="Dexterity" stat={stats.dexterity} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSaveProf} isEditable={isEditable} isProficient={saves.intelligence} statID="intelligence" name="Intelligence" stat={stats.intelligence} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSaveProf} isEditable={isEditable} isProficient={saves.wisdom} statID="wisdom" name="Wisdom" stat={stats.wisdom} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSaveProf} isEditable={isEditable} isProficient={saves.charisma} statID="charisma" name="Charisma" stat={stats.charisma} proficiencyBonus={proficiencyBonus}/>
            </Grid>
            <Heading size="md">Skills</Heading>
            <Grid templateColumns="1fr 1fr" gap={2} p="0px" fontSize="sm">
                <SkillBox handleChange={changeSkillProf} isEditable={isEditable} isProficient={skills.athletics} statID="athletics" name="Athletics" stat={stats.strength} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.acrobatics} statID="acrobatics" name="Acrobatics" stat={stats.dexterity} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.stealth} statID="stealth" name="Stealth" stat={stats.dexterity} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.sleightOfHand} statID="sleightOfHand" name="Sleight of Hand" stat={stats.dexterity} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.investigation} statID="investigation" name="Investigation" stat={stats.intelligence} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.history} statID="history" name="History" stat={stats.intelligence} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.arcana} statID="arcana" name="Arcana" stat={stats.intelligence} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.nature} statID="nature" name="Nature" stat={stats.intelligence} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.religion} statID="religion" name="Religion" stat={stats.intelligence} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.animalHandling} statID="animalHandling" name="Animal Handling" stat={stats.wisdom} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.insight} statID="insight" name="Insight" stat={stats.wisdom} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.perception} statID="perception" name="Perception" stat={stats.wisdom} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.survival} statID="survival" name="Survival" stat={stats.wisdom} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.medicine} statID="medicine" name="Medicine" stat={stats.wisdom} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.persuasion} statID="persuasion" name="Persuasion" stat={stats.charisma} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.deception} statID="deception" name="Deception" stat={stats.charisma} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.performance} statID="performance" name="Performance" stat={stats.charisma} proficiencyBonus={proficiencyBonus}/>
                <SkillBox handleChange={changeSkillProf}isEditable={isEditable} isProficient={skills.intimidation} statID="intimidation" name="Intimidation" stat={stats.charisma} proficiencyBonus={proficiencyBonus} />
            </Grid>
        </Flex>
    )
}

function SkillBox({isProficient, name, stat, proficiencyBonus, isEditable, statID, handleChange}) {
    let modifier = (stat-10)/2
    {if (isProficient) {
        modifier = modifier + proficiencyBonus
    }}

    return (
        <Flex bg="gray.700" border="0px" borderColor="pink.600" align="center" justify="space-between" p="1em" borderRadius="lg">
            <Text>{name}</Text>
            {isEditable ? <Flex align="center" justify="center" bg="gray.700"><FormLabel fontSize="xs" htmlFor={statID} mb="0px">Proficiency</FormLabel><Switch size="sm" onChange={handleChange} defaultChecked={isProficient} id={statID}/></Flex> :
            <Tag size="sm" variant="subtle" colorScheme={isProficient ? "pink": "whiteAlpha"}><TagLabel>{stat<10 ? Math.floor(modifier) : Math.ceil(modifier)}</TagLabel></Tag>}
        </Flex>
    )
}

export default SavingSkills