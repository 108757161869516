import { Flex, Spacer, Box, Tag, TagLabel, Input } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'

function Senses({senses, isEditable, handleSenseChange}) {
    return (
        <Flex gap="1em" h="100%" whiteSpace="nowrap">
            <SenseBox handleSenseChange={handleSenseChange} isEditable={isEditable} id="pp" name="P. Perception" value={senses.pp} />
            <SenseBox handleSenseChange={handleSenseChange} isEditable={isEditable} name="Speed"  id="speed" value={senses.speed} />
            <SenseBox handleSenseChange={handleSenseChange} isEditable={isEditable} name="Prof. Bonus" id="pb" value={senses.pb} />
            <SenseBox handleSenseChange={handleSenseChange} isEditable={isEditable} name="Initiative" id="init" value={senses.init} />
            <SenseBox handleSenseChange={handleSenseChange} isEditable={isEditable} name="Darkvision" id="darkvision" value={senses.darkvision} />
        </Flex>
    )
}

function SenseBox({name, value, isEditable, id, handleSenseChange}) {

    return (
        <Flex bg="gray.700" border="0px" boxSizing="border-box" borderColor="pink.800" margin="auto" p="0.5em" borderRadius="lg" gap={1} sx={{"scroll-snap-align":"start"}}>
            <Text fontSize="xs">{name}</Text>
            <Tag size="sm" variant="subtle" colorScheme="pink"><TagLabel> {isEditable ? <Input size="xs" onChange={handleSenseChange} textAlign="center" w="15px" id={id} variant="unstyled" placeholder={value}></Input> : value} </TagLabel></Tag>
        </Flex>
    )
}

export default Senses