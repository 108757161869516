import { ChakraProvider } from '@chakra-ui/react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import {Stats} from "./components/Stats"
import HP from "./components/HP"
import SavingSkills from "./components/SavingSkills"
import Senses from "./components/Senses"
import MainTab from "./components/Tab"
import {useState, useEffect} from "react"
import { collection, addDoc, setDoc, doc, getDoc} from "firebase/firestore";
import { auth, logInWithEmailAndPassword, registerWithEmailAndPassword, sendPasswordReset, query, where, getDocs} from "./firebase.js";
import {db} from './firebase.js';
import { useAuthState } from "react-firebase-hooks/auth";
import Login from "./components/Login.js"
import CharacterSelect from "./components/CharacterSelect.js"


function App() {
  const [character, setCharacter] = useState({ 
    senses:{
      "pp": 14,
      "init": 2,
      "pb": 3,
      "speed": 30,
      "darkvision": 60,},
    stats: {strength: 9,
            constitution: 14,
            dexterity: 14,
            intelligence: 12,
            wisdom: 10,
            charisma: 20},
    saves: {strength: false,
            constitution: true,
            dexterity: false,
            intelligence: false,
            wisdom: false,
            charisma: true},
    skills: {athletics: false,
             acrobatics: false,
             stealth: true,
             sleightOfHand: true,
             investigation: false,
             history: false,
             arcana: false, 
             nature: false,
             religion: false,
             animalHandling: false,
             insight: false,
             perception: false,
             survival: false,
             medicine: false,
             persuasion: true,
             deception: true,
             performance: false,
             intimidation: true},
     resources: [{name: "Sorcery Points", current: 4, max: 6}, {name: "Pact Magic", current:2, max: 2}],
     ac: 18,
     hp: {current: 44,
          max: 56},
     hitDice: {current: 2,
               max: 5,
               size: "d6"},     
     name: "Pacov Hansen",
        class: "Sorcerer",
        level: 5,
        alignment: "NE",
        race: "Goblin",
        background: "Urchin",
        traits: ["Trait 1", "Trait 2", "Trait 3"],
        flaws: ["Flaw 1", "Flaw 2", "Flaw 3"],
        bonds: ["Bond 1", "Bond 2", "Bond 3"],
        ideals: ["Ideal 1", "Ideal 2", "Ideal 3"],
        height: "120cm",
        weight: "30kg",
        hair: "black/purple",
        age: "15",
        skin: "Light green",
        spells: [{level: 0,
                  totalSpellSlots: "",
                  spellSlotsRemaining: "",
                  spells: [{
                    name: "Eldritch Blast",
                    description: "A beam of crackling energy streaks toward a creature within range. Make a ranged spell attack against the target. On a hit, the target takes 1d10 force damage. The spell creates more than one beam when you reach higher levels: two beams at 5th level, three beams at 11th level, and four beams at 17th level. You can direct the beams at the same target or at different ones. Make a separate attack roll for each beam.",
                    castingTime: "1 action",
                    range: "120 feet",
                    target: "A creature within range",
                    components: "V S",
                    duration: "Instantaneous",
                    school: "Evocation",
                  },]
                }, 
                {level: 1,
                totalSpellSlots: "4",
                spellSlotsRemaining: "2",
                spells: [{
                  name: "Eldritch Blast",
                  description: "A beam of crackling energy streaks toward a creature within range. Make a ranged spell attack against the target. On a hit, the target takes 1d10 force damage. The spell creates more than one beam when you reach higher levels: two beams at 5th level, three beams at 11th level, and four beams at 17th level. You can direct the beams at the same target or at different ones. Make a separate attack roll for each beam.",
                  castingTime: "1 action",
                  range: "120 feet",
                  target: "A creature within range",
                  components: "V S",
                  duration: "Instantaneous",
                  school: "Evocation"
                }]},
                {level: 2,
                totalSpellSlots: 2,
                spellSlotsRemaining: 2,
                spells: []}
        ],
        items: [{name: "Dagger",
                quantity: 1,
                weight: 4,
                value: "1 gp"
                },
              {name: "Potion of Minor Healing",
              quantity: 3,
              weight: 0.5,
              value: "50gp"}],
       coins: [{name: "GP",
                value: 49},
              {name: "SP",
                value: 11},
              {name: "CP",
              value: 0}],
       features: {
        race: [{name: "Nimble Escape",
                level: "", 
                description: "You can take the Disengage or Hide action as a bonus action on each of your turns."},
               {name: "Fey Ancestry",
                level: "",
                description: "You have advantage on saving throws you make to avoid or end the charmed condition on yourself."},
               {name: "Fury of the Small",
                level: "",
                description: "When you damage a creature with an attack or a spell and the creature's size is larger than yours, you can cause the attack or spell to deal extra damage to the creature. The extra damage equals your proficiency bonus. You can use this trait a number of times equal to your proficiency bonus, regaining all expended uses when you finish a long rest, and you can use it no more than once per turn."}],
        class: [{name: "Telepathic Speech",
                 level: "1",
                 description: "You can form a telepathic connection between your mind and the mind of another. As a bonus action, choose one creature you can see within 30 feet of you. You and the chosen creature can speak telepathically with each other while the two of you are within a number of miles of each other equal to your Charisma modifier (minimum of 1 mile). To understand each other, you each must speak mentally in a language the other knows. The telepathic connection lasts for a number of minutes equal to your sorcerer level. It ends early if you are incapacitated or die or if you use this ability to form a connection with a different creature." },
                {name: "Font of Magic",
                 level: "2",
                 description: `At 2nd level, you tap into a deep wellspring of magic within yourself. This wellspring is represented by sorcery points, which allow you to create a variety of magical effects.`},
                {name: "Metamagic: Subtle Spell",
                level: "3",
                description: `When you cast a spell, you can spend 1 sorcery point to cast it without any somatic or verbal components.`},
                {name: "Metamagic: Twinned Spell",
                 level: "3",
                 description: `When you cast a spell that targets only one creature and doesn't have a range of self, you can spend a number of sorcery points equal to the spell's level to target a second creature in range with the same spell (1 sorcery point if the spell is a cantrip).

                 To be eligible, a spell must be incapable of targeting more than one creature at the spell's current level. For example, magic missile and scorching ray aren't eligible, but ray of frost and chromatic orb are.`},
                 {name: "Hexblade's Curse",
                  level: "1",
                  description: `Starting at 1st level, you gain the ability to place a baleful curse on someone. As a bonus action, choose one creature you can see within 30 feet of you. The target is cursed for 1 minute. The curse ends early if the target dies, you die, or you are incapacitated. Until the curse ends, you gain the following benefits:

                  You gain a bonus to damage rolls against the cursed target. The bonus equals your proficiency bonus.
                  Any attack roll you make against the cursed target is a critical hit on a roll of 19 or 20 on the d20.
                  If the cursed target dies, you regain hit points equal to your warlock level + your Charisma modifier (minimum of 1 hit point).
              
                  You can't use this feature again until you finish a short or long rest.`},
                  {name: "Hex Warrior",
                  level: "1",
                  description: `At 1st level, you acquire the training necessary to effectively arm yourself for battle. You gain proficiency with medium armor, shields, and martial weapons.

                  The influence of your patron also allows you to mystically channel your will through a particular weapon. Whenever you finish a long rest, you can touch one weapon that you are proficient with and that lacks the two-handed property. When you attack with that weapon, you can use your Charisma modifier, instead of Strength or Dexterity, for the attack and damage rolls. This benefit lasts until you finish a long rest. If you later gain the Pact of the Blade feature, this benefit extends to every pact weapon you conjure with that feature, no matter the weapon's type.`},
                  {name: "Eldritch Invocation: Agonizing Blast",
                  level: "2",
                  description: `When you cast eldritch blast, add your Charisma modifier to the damage it deals on a hit.` },
                  {name: "Eldritch Invocation: Mask of Many Faces",
                  level: "2",
                  description: `You can cast disguise self at will, without expending a spell slot.` }
                ],
        feats: [],
       }                 
  })

  const [user, loading, error] = useAuthState(auth);

  const [selectedCharacter, setSelectedCharacter] = useState(null)

  const [isLoading, setIsLoading] = useState(false)

  const [isEditable, setIsEditable] = useState(false)

  async function getChar() {

    try {
      const docRef = doc(db,"users","qBHbw24Nz9RlmbQ09QXu1cGkaMf2", "characters", "Pacov")
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setCharacter(docSnap.data())
        setIsLoading(true)
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }

    } catch(e) {
      console.error("Error getting document: ", e)
    }
  }

  async function saveChar() {  
       
    try {
        await setDoc(doc(db, "users",user.uid, "characters", selectedCharacter), character);
        console.log("Document written with ID: ");
        console.log(user.uid)
        console.log("Saving char")
      } catch (e) {
        console.error("Error adding document: ", e);
      }
}

// useEffect(() => {
//   getChar();

// }, [])

function increaseItemQuantity(itemName) {
  console.log("Increasing item quantity of", itemName)
  let oldChar = {...character}
  let inventory = oldChar.items
  console.log("Inventory before", inventory)
  inventory.map((item) => {if (item.name === itemName) {item.quantity += 1}})
  console.log("inventory after", inventory)
  oldChar.items = inventory
  setCharacter(oldChar)

}

function decreaseItemQuantity(itemName) {
  let oldChar = {...character}
  let inventory = oldChar.items
  inventory.map((item) => {if (item.name === itemName) {item.quantity -= 1}})
  inventory = inventory.filter(item => item.quantity != 0)
  oldChar.items = inventory
  setCharacter(oldChar)
}

function characterSelectScreen() {
  setSelectedCharacter(null)
}

function saveSpellSlots(spellSlotsObject) {
  let oldChar = {...character};
  oldChar.spells = spellSlotsObject;
  setCharacter(oldChar)
}

  function toggleEdit() {
    setIsEditable(true)
  }

  function saveEdit() {
    saveChar()
    setIsEditable(false)
  }

  function changeSkillProf(e) {
    let oldChar = {...character}
    let id = e.target.getAttribute("id")
    let value = e.target.checked
    oldChar.skills[id] = value
    setCharacter(oldChar)
  }

  function changeSaveProf(e) {
    let oldChar = {...character}
    let id = e.target.getAttribute("id")
    let value = e.target.checked
    oldChar.saves[id] = value
    setCharacter(oldChar)
  }

  function handleGenericChange(e) {
    let oldChar = {...character}
    let id = e.target.getAttribute("id")
    let value = Number(e.target.value)
    oldChar[id] = value
    setCharacter(oldChar)
  }

  function handleCharacterChange(e) {
    let oldChar = {...character}
    let id = e.target.getAttribute("id")
    let value = e.target.value
    oldChar[id] = value
    setCharacter(oldChar)
  }

  function handleTraitChange(e) {
    let oldChar = {...character}
    let id = e.target.getAttribute("id")
    let value = e.target.value
    value = value.split(",")
    oldChar[id] = value
    setCharacter(oldChar)
  }

  function handleSenseChange(e) {
    let oldChar = {...character}
    let id = e.target.getAttribute("id")
    let value = Number(e.target.value)
    oldChar.senses[id] = value
    setCharacter(oldChar)
  }

  function removeAttack(attackName) {
    let oldChar = {...character}
    let filteredAttacks = oldChar.attacks.filter((attack) => attack.name !== attackName)
    oldChar.attacks = filteredAttacks
    setCharacter(oldChar)
  }

  function handleStatChange(e) {
    let oldChar = {...character}
    let statName = e.target.getAttribute("id")
    statName = statName.toLowerCase()
    let value = e.target.value
    oldChar.stats[statName] = value
    setCharacter(oldChar)
  }

  function reduceHitDie() {
    let oldChar = {...character}
    if (oldChar.hitDice.current > 0) {
      oldChar.hitDice.current -= 1
    }
    setCharacter(oldChar)
    saveChar()
  }

  function longRest() {
    let oldChar = {...character}
    oldChar.hp.current = oldChar.hp.max
    oldChar.spells.map((spellLevel) => {
      spellLevel.spellSlotsRemaining = spellLevel.totalSpellSlots
    } )
    oldChar.hitDice.current = oldChar.hitDice.max
    oldChar.hp.temp = 0
    oldChar.resources.map((resource) => {
      resource.current = resource.max
    })
    setCharacter(oldChar)
    saveChar()
  }

  function shortRest(hitDiceToSpend) {
    let oldChar = {...character}
    oldChar.hitDice.current -= hitDiceToSpend
    oldChar.resources.map((resource) => {if (resource.rechargesOn === "shortRest") {resource.current = resource.max}})
    setCharacter(oldChar)
  }

  function adjustMaxHP(e) {
    let oldChar = {...character}
    oldChar.hp.max = Number(e.target.value)
    setCharacter(oldChar)
    saveChar()
  }

  function saveHP() {
    let oldChar = {...character}
    let newHP = document.querySelector("#hpChange").value
    oldChar.hp.current = newHP
    setCharacter(oldChar)
    saveChar()
  }

  function takeDamage(value) {
    let oldChar = {...character}
    if (oldChar.hp.temp > 0) {
      if (oldChar.hp.temp < value) {
        let spillover = value - oldChar.hp.temp
        oldChar.hp.temp = 0
        oldChar.hp.current -= spillover
      } else {
        oldChar.hp.temp -= value
      }
    } else {
      oldChar.hp.current -= value
    }
    setCharacter(oldChar)
    saveChar()
    console.log("Take damage")

  }

  function healDamage(value) {
    let oldChar = {...character}
    if (oldChar.hp.current + value <= oldChar.hp.max) {
      oldChar.hp.current += value
    } else {
      oldChar.hp.current = oldChar.hp.max
    }
    setCharacter(oldChar)
    // saveChar()
    console.log("Healing damage")
  }

  function addTempHP(value) {
    let oldChar = {...character}
    oldChar.hp.temp = Number(value) 
    setCharacter(oldChar)
    saveChar()
  }

  function changeSpellcastingStat(e) {
    let value = e.target.value
    let oldChar = {...character}
    oldChar.spellcastingStat = value
    setCharacter(oldChar)
  }

  function useSpellSlot(e) {
    let level = e.target.getAttribute("data-id")
    console.log(level)
    let oldChar = {...character}
    oldChar.spells.map((spellLevel) => {
      if (spellLevel.level == level && spellLevel.spellSlotsRemaining > 0) {
        spellLevel.spellSlotsRemaining -= 1
      }
    })
    setCharacter(oldChar)
    saveChar()
  }

  function useResource(e) {
    let name = e.target.getAttribute("data-id")
    console.log(name)
    let oldChar = {...character}
    oldChar.resources.map((resource) => {
      if (resource.name === name && resource.current > 0) {
        resource.current -= 1
      }
    })
    setCharacter(oldChar)
    saveChar()
  }

  function addSpell(spellObject) {
    let oldChar = {...character}
    let level = spellObject.level
    if (oldChar.spells[level]) {
      oldChar.spells[level].spells.push(spellObject)
    setCharacter(oldChar)
    saveChar()
    } else {
      console.log("Spell can't be added, no spell level")
    }
    
  }

  function deleteFeature(e) {
    let oldChar = {...character}
    let id = e.target.getAttribute("data-id")
    let type = e.target.getAttribute("data-type")
    let newFeatList = oldChar.features[type].filter(feature => feature.name != id)
    oldChar.features[type] = newFeatList
    setCharacter(oldChar)
  }

  function deleteResource(e) {
    let oldChar = {...character}
    let id = e.target.getAttribute("data-id")
    let newResourceList = oldChar.resources.filter(res => res.name != id)
    oldChar.resources = newResourceList
    setCharacter(oldChar)
  }

  function deleteSpell(e) {
    let oldChar = {...character}
    let id = e.target.getAttribute("data-id")
    let level = e.target.getAttribute("data-level")
    level = Number(level)
    let spellLevel = oldChar.spells[level]
    let filteredSpellList = spellLevel.spells.filter(spell => spell.name != id)
    oldChar.spells[level].spells = filteredSpellList
    setCharacter(oldChar)
  }

  function addFeat(featObject) {
    let oldChar = {...character}
    let type = featObject.type
    oldChar.features[type].push(featObject)
    setCharacter(oldChar)
  }

  function addResource(resourceObject) {
    let oldChar = {...character}
    oldChar.resources.push(resourceObject)
    setCharacter(oldChar)
  }

  function addAttack(attackObject) {
    let oldChar = {...character}
    oldChar.attacks.push(attackObject)
    setCharacter(oldChar)
  }

  function addItem(itemObject) {
    let oldChar = {...character}
    oldChar.items.push(itemObject)
    setCharacter(oldChar)
    saveChar()
  }

  function updateCoinValue(e) {
    let newValue = e.target.value
    let coinType = e.target.getAttribute("id")
    let oldChar = {...character}
    oldChar.coins.map(coin => {
      if (coin.name === coinType) {coin.value = newValue}
    })
    setCharacter(oldChar)
  }

  async function selectCharacter(e, {userID}) {
    console.log("User ID:", userID)
    let characterID = e.target.getAttribute("data-id");
    console.log("Character ID:", characterID)
    
    try {
      const docRef = doc(db,"users",userID,"characters",characterID)
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setCharacter(docSnap.data())
        setIsLoading(true)
        setSelectedCharacter(characterID)
        console.log("Selected character with id", characterID)
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }

    } catch(e) {
      console.error("Error getting document: ", e)
    }
  }


  return (
  <ChakraProvider h="100%">
    {user == null ? 
      <Login />
   : user && !selectedCharacter ? 
   <CharacterSelect selectCharacter={selectCharacter} userID={user.uid}></CharacterSelect>
   :
      <Grid templateColumns="repeat(12, 1fr)" gap ={4} templateRows="2fr 1fr 10fr" h="100vh" padding="1em" color="gray.50" bg="gray.900" w="100vw">
        <GridItem colStart={5} colEnd={10} rowStart={1} rowEnd={2} bg="gray.900">
          <Stats isEditable={isEditable} stats={character.stats} handleStatChange={handleStatChange} />
        </GridItem>
        <GridItem colStart={10} colEnd={13} rowStart={1} rowEnd={3} bg="gray.800" borderRadius="lg">
          <HP addTempHP={addTempHP} shortRest={shortRest} adjustMaxHP={adjustMaxHP} characterSelectScreen={characterSelectScreen} healDamage={healDamage} takeDamage={takeDamage} deleteResource={deleteResource} addResource={addResource} getChar={getChar} toggleEdit={toggleEdit} saveEdit={saveEdit} isEditable={isEditable} saveChar={saveChar} 
          reduceHitDie={reduceHitDie} decreaseResource={useResource} saveHP={saveHP} longRest={longRest} hp={character.hp} ac={character.ac} hitDice={character.hitDice} 
          classResource={character.resources} hasInspiration handleACChange={handleGenericChange} />
          </GridItem>
        <GridItem colStart={1} colEnd={5} rowStart={1} rowEnd={4} bg="gray.800" p="1em" borderRadius="lg" overflowY="auto" sx={{ "::-webkit-scrollbar":{
           display:"none",}, "scrollbar-width":"none"}}>
            <SavingSkills changeSkillProf={changeSkillProf} changeSaveProf={changeSaveProf} isEditable={isEditable} stats={character.stats} skills={character.skills} proficiencyBonus={character.senses.pb} saves={character.saves}/>
        </GridItem>
        <GridItem colStart={5} colEnd={13} rowStart={3} rowEnd={4} bg="gray.800" borderRadius="lg" overflowY="auto" sx={{ "::-webkit-scrollbar":{
           display:"none",
       },
         "scrollbar-width":"none"}}>
          <MainTab isEditable={isEditable} changeSpellcastingStat={changeSpellcastingStat} removeAttack={removeAttack} decreaseItemQuantity={decreaseItemQuantity} increaseItemQuantity={increaseItemQuantity} saveSpellSlots={saveSpellSlots} selectCharacter={selectCharacter} updateCoinValue={updateCoinValue} deleteSpell={deleteSpell} deleteFeature={deleteFeature} handleTraitChange={handleTraitChange} handleCharacterChange={handleCharacterChange} addItem={addItem} addAttack={addAttack} addFeat={addFeat} useSpellSlot={useSpellSlot} addSpell={addSpell} character={character}/>
          </GridItem>
        <GridItem colStart={5} colEnd={10} rowStart={2} rowEnd={3} bg="gray.800" borderRadius="lg" overflowY="auto" overflowX="auto" sx={{ "::-webkit-scrollbar":{
           display:"none",
       },
         "scrollbar-width":"none",
         "scroll-snap-type":"x mandatory"}}>
          <Senses handleSenseChange={handleSenseChange} isEditable={isEditable} senses={character.senses}/>
        </GridItem>

      </Grid>
}

  </ChakraProvider>

  );
}

export default App;
