import { Flex, Spacer, Box, Editable, EditableInput, EditableTextarea,EditablePreview, Input} from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'

function Stats({stats, isEditable, handleStatChange}) {
    return (
        <Flex h="100%" w="100%" justify="space-between" gap={1} color="gray.50" borderRadius="lg" overflowY="auto">
            <StatBlock isEditable={isEditable} handleStatChange={handleStatChange} stat={{"name":"Strength", value : stats.strength}}/>
            <StatBlock isEditable={isEditable} handleStatChange={handleStatChange} stat={{"name":"Constitution", value: stats.constitution}}/>
            <StatBlock isEditable={isEditable} handleStatChange={handleStatChange} stat={{"name":"Dexterity", value: stats.dexterity}}/>
            <StatBlock isEditable={isEditable} handleStatChange={handleStatChange} stat={{"name":"Intelligence", value: stats.intelligence}}/>
            <StatBlock isEditable={isEditable} handleStatChange={handleStatChange} stat={{"name":"Wisdom", value:stats.wisdom}}/>
            <StatBlock isEditable={isEditable} handleStatChange={handleStatChange} stat={{"name":"Charisma", value:stats.charisma}}/>
        </Flex>
    )
}

function StatBlock({stat, isEditable, handleStatChange}) {
    const modifier = (stat.value-10)/2


    return (
        <Flex className="statBlock" flex="1 1 0px" p="1em" direction="column" align="center" border="0px" borderColor="pink.800" bg="gray.800" borderRadius="lg" gap={1} width="16%">
            <Box fontSize={["1.25em", "1.25em", "1.25em", "1.5em"]} bg="gray.800" p="0.5em" borderRadius="lg" textAlign="center">{(stat.value<10 ? Math.floor(modifier) : Math.ceil(modifier))}</Box>
            <Text fontSize="xs" textAlign="center">{stat.name}</Text>
            {isEditable ? <Input bg="gray.700" w="100%" id={stat.name} onChange={handleStatChange} textAlign="center" borderRadius="lg" textColor="pink.50" placeholder={stat.value} variant="unstyled"></Input> : 
            <Box bg="gray.700" w="100%" textAlign="center" borderRadius="lg" textColor="pink.50">{stat.value}</Box>
}
        </Flex>
    )
}

function StaticBlock({stat, handleClick, isEditable, handleChange, id}) {
    return (
        <Flex className="statBlock" p="0.5em" flex="1 1 0px" direction="column" justify="space-between" align="center" bg="gray.600" borderRadius="lg" onClick={handleClick}>
            <Box fontSize={["1em", "1em", "1em", "1.25em"]}>{isEditable? <Input variant="unstyled" textAlign="center" fontSize={["1em", "1em", "1.25em"]} id={id} placeholder={stat.value} onChange={handleChange}></Input> : stat.value}</Box>
            <Text fontSize={["xs", "xs", "xs", "sm"]}>{stat.name}</Text>
        </Flex>
    )
}

export {StaticBlock, Stats}