const newCharacter ={
    spellcastingStat: null,    
    attacks: [{name: "Unarmed",
               modifier: "+3",
               damageDice: "Strength"}],
    senses:{
    "pp": 0,
    "init": 0,
    "pb": 0,
    "speed": 0,
    "darkvision": 0,},
  stats: {strength: 10,
          constitution: 10,
          dexterity: 10,
          intelligence: 10,
          wisdom: 10,
          charisma: 20},
  saves: {strength: false,
          constitution: false,
          dexterity: false,
          intelligence: false,
          wisdom: false,
          charisma: false},
  skills: {athletics: false,
           acrobatics: false,
           stealth: false,
           sleightOfHand: false,
           investigation: false,
           history: false,
           arcana: false, 
           nature: false,
           religion: false,
           animalHandling: false,
           insight: false,
           perception: false,
           survival: false,
           medicine: false,
           persuasion: true,
           deception: true,
           performance: false,
           intimidation: false},
   resources: [],
   ac: 10,
   hp: {current: 0,
        max: 0,
        temp: 0},
   hitDice: {current: 1,
             max: 1,
             size: ""},     
   name: "",
      class: "",
      level: 1,
      alignment: "",
      race: "",
      background: "",
      traits: ["Trait 1", "Trait 2", "Trait 3"],
      flaws: ["Flaw 1", "Flaw 2", "Flaw 3"],
      bonds: ["Bond 1", "Bond 2", "Bond 3"],
      ideals: ["Ideal 1", "Ideal 2", "Ideal 3"],
      height: "0",
      weight: "0kg",
      hair: "",
      age: "",
      skin: "",
      spells: [],
      items: [],
     coins: [{name: "GP",
              value: 0},
            {name: "SP",
              value: 0},
            {name: "CP",
            value: 0}],
     features: {
      race: [],
      class: [],
      feats: [],
     }                 
}

export default newCharacter