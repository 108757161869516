import {
    Input,
    Button,
    Flex,
    Heading,
    Text,
    Link
  } from '@chakra-ui/react'

import {useState, useEffect} from "react"
import { auth, logInWithEmailAndPassword, registerWithEmailAndPassword, sendPasswordReset, query, collection, db, where, getDocs} from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {CreateCharacter} from "./CharacterSelect.js"
import CharacterSelect from "./CharacterSelect.js"
import movePacov from "./DatabaseActions.js"

  function Login({selectCharacter}) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    //const [user, loading, error] = useAuthState(auth);
    const [name, setName] = useState("");
    const [userName, setUserName] = useState("")
    const [newUser, setnewUser] = useState(false)
    const [forgotPassword, setforgotPassword] = useState(false)
    // const fetchUserName = async () => {
    //     try {
    //       const q = query(collection(db, "users"), where("uid", "==", user?.uid));
    //       const doc = await getDocs(q);
    //       const data = doc.docs[0].data();
    //       console.log(doc)
    //       console.log(data)
    //       setUserName(data.name);
    //     } catch (err) {
    //       console.error(err);
    //       alert("An error occured while fetching user data");
    //     }
    //   };
    //   useEffect(() => {
    //     if (loading) return;
    //     fetchUserName();
    //   }, [user, loading]);

    return (
        <Flex bg="gray.800" w="100%" h="100vh" justify="center" align="center" color="pink.50">
        <Flex direction="column" gap="1em" w="500px">
            { !newUser ?
            <Flex direction="column" gap="1em">
                <Heading>Log in</Heading>
                <Input variant="flushed" placeholder="Email-address" onChange={(e) => setEmail(e.target.value)}></Input>
                <Input variant="flushed"placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)}></Input>
                <Flex gap="1em" align="center">
                <Button colorScheme="pink" w="15%" onClick={() => logInWithEmailAndPassword(email, password)}>Log in</Button>
                <Button colorScheme="pink" w="30%" variant="outline" onClick={() => setforgotPassword(!forgotPassword)}>Forgot password</Button>
                <Link color="pink.600" onClick={() => setnewUser(!newUser)}>New user? Register here</Link>
                </Flex>
            </Flex>
            :
            <Flex direction="column" gap="1em">
                <Heading>Register</Heading>
                <Input variant="flushed" placeholder="Full name" onChange={(e) => setName(e.target.value)}></Input>
                <Input variant="flushed" placeholder="Email-address" onChange={(e) => setEmail(e.target.value)}></Input>
                <Input variant="flushed" placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)}></Input>
                <Flex gap="1em" align="center">
                <Button colorScheme="pink" w="15%" variant="outline" onClick={() => registerWithEmailAndPassword(name, email, password)}>Register</Button>
                
                <Link color="pink.600" onClick={() => setnewUser(!newUser)}>Back to login</Link>
                </Flex>

            </Flex>
  }
            { forgotPassword ?
            <Flex direction="column" gap="1em">
                <Heading>Forgot password?</Heading>
                <Input variant="flushed" placeholder="Email" onChange={(e) => setEmail(e.target.value)}></Input>
                <Button colorScheme="pink" onClick={() => sendPasswordReset}>Send email</Button>
            </Flex> : null
            }

            {/* <CreateCharacter userID={user.uid} />
            <CharacterSelect selectCharacter={selectCharacter} userID={user.uid} />
            <Button colorScheme="pink" onClick={() => movePacov()}>Move Pacov</Button> */}

        </Flex>
        </Flex>
    )

  }

  export default Login